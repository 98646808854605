import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import * as CONST from "../../components/const";

const kv_h = "../../images/security-service/kv_h.jpeg";
const kv_v = "../../images/security-service/kv_v.jpg";
const bg_h = "../../images/security-service/bg_h.png";
const bg_v = "../../images/security-service/bg_v.png";
const img1 = "../../images/security-service/img1.jpg";
const img2 = "../../images/security-service/img2.jpg";
const img3 = "../../images/security-service/img3.jpg";
const img4 = "../../images/security-service/img4.jpeg";

function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "none"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        keywords={[`IT`, `ネットワーク`, `開発`, `東京`]}
        title="Security Service"
      />

      <section className={CONST.SECTION_AREA_CLASS + " xl:pb-28"}>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.SECURITYSERVICE_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.SECURITYSERVICE_BACK_GROUND_PATTERN_CLASS} src={bg_v} />
        <div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS + " xl:text-white"}>
          <p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Security Service<span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>セキュリティサービス</span></p>
        </div>

        <div className={CONST.KEY_VISUAL_LEFT_IMG_AREA_CLASS}>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_h} />
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS} src={kv_v} />
        </div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_OFFSET_CLASS}></div>
        <div data-aos="fade-left" className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_CLASS + " xl:pt-10 2xl:pt-10 3xl:pt-10 tracking-wider sm:leading-11 "}>
          <div >
            <h1 className={CONST.H1_CLASS + " font-light sm:leading-tight"}>
              <span className="">人、企業、<br></br>そして社会を護る</span>
            </h1>
            <p>いつ起こるか分からないサイバー攻撃。</p>
            <p>対処するためには、知識を持った人材を育てることが重要です。</p>
            <p>当社ではお客様の&quot;ビジネス&quot;を護ることができるようなセキュリティコンサルティングを行っております。</p>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>体制の整備</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img1} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-20"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>体制の整備</h2>
            <p>・CSIRT構築支援</p>
            <p>・リスクアセスメント</p>
			      <p>・ISMS認証取得支援</p>
			      <p>・ISMS認証取得支援</p>
            <p>・各種セキュリティ対策のご提案</p><br></br>
            <p>お客様の組織に合ったシステムの構築を手助け致します。</p>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_REVERSE_CLASS}>
        <div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>安全性の向上</h2>
            <p>・脆弱性診断</p>
			      <p>・ペネトレーション</p>
			      <p>・ソフトウェアテスト</p><br></br>
			      <p>システムにセキュリティ上の問題がないかを定期的に確認し、サイバー攻撃を予防致します。</p>
          </div>
        </div>
        <div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">安全性の向上</span></h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img2} />
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>セキュリティ教育</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img3} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " 3xl:-mt-20"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>セキュリティ教育</h2>
            <p>・サイバーセキュリティ教育</p>
		      	<p>・インシデント対応訓練</p>
			      <p>・標的型メール訓練</p><br></br>
		      	<p>サイバー攻撃の予防/対処ができる人材を育成致します。</p>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={CONST.SECTION_AREA_REVERSE_CLASS}>
        <div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS}>サイバー攻撃への対処</h2>
            <p>・対応支援(調査報告、再発防止対策)</p>
			      <p>・デジタルフォレンジック</p><br></br>
			      <p>サイバー攻撃を受けた際、被害を最大限抑えることができるようサポート致します。</p>
          </div>
        </div>
        <div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}><span className="inline-block">サイバー犯罪への対処</span></h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full -mb-10 xl:mb-0" src={img4} />
        </div>
      </section>

    </Layout >
  );
}

export default IndexPage;
